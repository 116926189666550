import React from "react";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Container, Text, Icon } from "@atoms";
import { FooterNav, MailchimpForm, Social } from "@molecules";
import { AppLink } from "@base";
import useAcFooterData from "@staticQueries/FooterQuery";
import useOpenStudiosFooter from "@staticQueries/OpenStudiosFooterQuery";
import useWatsonvilleFooter from "@staticQueries/WatsonvilleFooterQuery";

const Footer = () => {
  const lang = useLang();

  const site = process.env.GATSBY_CRAFT_HANDLE;

  const osFooterData = useOpenStudiosFooter()[lang === "en" ? "en" : "es"];
  const wvFooterData = useWatsonvilleFooter()[lang === "en" ? "en" : "es"];
  const acFooterData = useAcFooterData()[lang === "en" ? "en" : "es"];

  const footerData = {
    openStudios: osFooterData,
    watsonville: wvFooterData,
    default: acFooterData,
  }[site];

  const { privacyLink, copyright, tax, nav, social } = acFooterData;
  const { address } = footerData;

  return (
    <footer className="mt-auto bg-black py-20 text-white md:py-28">
      <Container>
        <div className="flex flex-wrap md:flex-nowrap">
          <div className="w-full pb-12 md:w-1/2 md:pb-24 md:pr-20 lg:w-2/3">
            <MailchimpForm
              heading={t("Get Our Newsletter & Stay Informed", lang)}
              color="white"
            />
          </div>
          <div className="flex-1 border-t-2 border-white pt-12 pb-12 md:border-t-0 md:border-l-2 md:pl-12 md:pl-20 md:pb-24 md:pt-0">
            <Social social={social} />
          </div>
        </div>
        <div
          className={classNames(
            "flex flex-wrap gap-12 border-t-2 border-white pt-12 sm:justify-between md:gap-20 md:pt-28"
          )}
        >
          {/* organization information */}
          <div className="flex w-full flex-shrink-0 flex-col space-y-4 md:w-auto md:max-w-sm">
            <div className="h-auto w-24">
              <Icon name="logo" fitHeight className="h-full w-full" />
            </div>
            <Text variant="xs">
              {/* todo: cheaper cleanup here */}
              {`&copy; ${new Date().getFullYear()} ${copyright.replace(
                /<.*?>/g,
                ""
              )}`}
            </Text>
            <div className="space-y-4 md:space-y-2">
              <AppLink
                className="text-sm underline decoration-transparent decoration-2 underline-offset-4 duration-300  hover:decoration-white xl:text-xs"
                to={privacyLink.url}
              >
                {privacyLink.text}
              </AppLink>
              <Text variant="xs">{tax}</Text>
            </div>
            <Text variant="xs">{address}</Text>
          </div>
          {/* nav */}
          <div className="flex w-full flex-1 md:justify-end">
            <FooterNav nav={nav} />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
